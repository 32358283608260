<template>
  <div class="wrapper">
    <objekto-logo class="fill-current mb-8 h-20" />
    <well class="w-full sm:w-136 text-primary">
      <h1 class="font-semibold text-lg mb-3">
        {{ $t('account-not-configured.title') }}
      </h1>
      <p>{{ $t('account-not-configured.info') }}</p>

      <btn class="mt-8 w-full" theme="secondary" @click="logout">
        {{ $t('account-not-configured.logout') }}
      </btn>
    </well>
  </div>
</template>

<script>
import ObjektoLogo from '@/assets/Objekto_Logo.svg';
import Well from '@/components/Well/Well.vue';
import Btn from '@/components/Button/Button';
import { mapActions } from 'vuex';

export default {
  components: {
    ObjektoLogo,
    Well,
    Btn,
  },
  methods: mapActions('user', ['logout']),
};
</script>
<style scoped>
.wrapper {
  @apply p-4 flex flex-col items-center justify-center h-screen text-white bg-cover;
  background-image: url('../assets/background.png');
}
</style>
